import images from "./images";

const bakmi = [
  {
    title: "Mie Ayam/Yamin Special",
    price: "23K",
    tags: "Mie Ayam | 2 Baso | 2 Ceker Ayam | 2 Pangsit",
  },
  {
    title: "Mie Ayam/Yamin",
    price: "15K",
    tags: "Only Mie Ayam",
  },
  {
    title: "Mie Ayam/Yamin Pangsit",
    price: "16K",
    tags: "Mie Ayam | 2 Pangsit",
  },
  {
    title: "Mie Ayam/Yamin Pangsit Baso",
    price: "19K",
    tags: "Mie Ayam | 2 Baso | 2 Pangsit",
  },
  {
    title: "Mie Ayam/Yamin Ceker",
    price: "18K",
    tags: "Mie Ayam | 2 Ceker Ayam",
  },
  {
    title: "Mie Ayam/Yamin Baso",
    price: "18K",
    tags: "Mie Ayam | 2 Baso",
  },
];

const cocktails = [
  {
    title: "Es Jeruk",
    price: "8K",
    tags: "Jeruk Manis",
  },
  {
    title: "Es Teh Manis",
    price: "5K",
    tags: "Tea with Sugar and Ice",
  },
  {
    title: "Teh Tawar",
    price: "2K",
    tags: "Only Tea without Sugar and Ice",
  },
  {
    title: "Teh Botol",
    price: "7K",
    tags: "Tea Botol",
  },
  {
    title: "Aqua Botol",
    price: "5K",
    tags: "Water",
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: "Bib Gourmond",
    subtitle: "Lorem ipsum dolor sit amet, consectetur.",
  },
  {
    imgUrl: images.award01,
    title: "Rising Star",
    subtitle: "Lorem ipsum dolor sit amet, consectetur.",
  },
  {
    imgUrl: images.award05,
    title: "AA Hospitality",
    subtitle: "Lorem ipsum dolor sit amet, consectetur.",
  },
  {
    imgUrl: images.award03,
    title: "Outstanding Chef",
    subtitle: "Lorem ipsum dolor sit amet, consectetur.",
  },
];

export default { bakmi, cocktails, awards };
