import React from "react";
import { FiFacebook, FiInstagram, FiTwitter } from "react-icons/fi";
import { FooterOverlay, Newsletter } from "../../components";
import { images } from "../../constants";
import "./Footer.css";

const Footer = () => (
  <div className="app__footer section__padding app__bg" >
    <FooterOverlay />
    <Newsletter />

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans">
        RT 2 RW 3 Cipayung, Jl. Raya Setu, Jakarta Timur
        </p>
        <p className="p__opensans">+62 813-1015-5214</p>
      </div>
      <div className="app__footer-links_logo">
        <img src={images.gericht} alt="footer" />
        <p className="p__opensans">
          {" "}
          "The best way to find yourself in the service of others"{" "}
        </p>
        <img src={images.spoon} alt="spoon" className="spoon__img" />
        <div className="app__footer-links_icons">
          <FiFacebook />
          <FiTwitter />
          <FiInstagram />
        </div>
      </div>
      <div className="app__footer-links_work">
      <h1 className="app__footer-headtext">Working Hours</h1>
        <p className="p__opensans">
          Monday-Saturday
        </p>
        <p className="p__opensans">08:00 AM - 05:00 PM</p>
      </div>
    </div>
    <div className="app__footer__copyright">
      <p className="p__opensans"> 2024 Bakmi Yanto . All Rights Reserved</p>
    </div>
  </div>
);

export default Footer;
