import React from "react";
import { images, data } from "../../constants";
import { SubHeading, MenuItem } from "../../components";
import "./SpecialMenu.css";

const SpecialMenu = () => (
  <div className="app__specialMenu flex__center section__padding " id="menu">
    <div className="app__specialMenu-title">
      <SubHeading title="Menu That Fits Your Palatte" />
      <h1 className="headtext__cormorant"> Today's Special</h1>
    </div>
    <div className="app__specialMenu-menu">
      <div className="app__speacialMenu-menu_wine flex__center">
        <p className="app__speacialMenu-menu_heading">Mie Ayam</p>
        <div className="app__specialMenu-menu_items">
          {data.bakmi.map((bakmi, index) => (
            <MenuItem
              key={bakmi.title + index}
              title={bakmi.title}
              price={bakmi.price}
              tags={bakmi.tags}
            />
          ))}
        </div>
      </div>

      <div className="app__specialMenu-menu_img">
        <img src={images.menu} alt="menu" />
      </div>

      <div className="app__speacialMenu-menu_cocktail flex__center">
        <p className="app__speacialMenu-menu_heading">Drinks</p>
        <div className="app__specialMenu-menu_items">
          {data.cocktails.map((cocktail, index) => (
            <MenuItem
              key={cocktail.title + index}
              title={cocktail.title}
              price={cocktail.price}
              tags={cocktail.tags}
            />
          ))}
        </div>
      </div>
    </div>
    {/* <div className="app__speacialMenu-button">
      <button className="custom__button">View More</button>
    </div> */}
  </div>
);

export default SpecialMenu;
